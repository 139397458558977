<template>
  <div class="header isFixed" :class="{'blue': this.$route.meta.isBlueBj || hasBj , 'hide': this.$route.meta.hasNav}">
    <header>
      <div class="container flex flex-content-between">
        <div></div>
        <router-link to="/" class="logo">
          <img class="logo-yinqin" src="../../static/images/home/logo.jpg" />
          <img
            class="logo-img logo1"
            src="../../static/images/home/logo.svg"
          />
          <img
            class="logo-img logo2"
            src="../../static/images/home/slogon.svg"
          />
        </router-link>
        <div class="menu flex">
          <el-menu :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal">
            <el-menu-item index="/"><router-link to="/">首页</router-link></el-menu-item>
            <el-menu-item index="/media">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <router-link to="/media">媒体资源库</router-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><router-link to="/media">网络媒体</router-link></el-dropdown-item>
                  <!-- <el-dropdown-item><router-link to="/zmt">自媒体</router-link></el-dropdown-item> -->
                  <el-dropdown-item><router-link to="/hwmt">海外媒体</router-link></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="/team"><router-link to="/team">文案天团</router-link></el-menu-item>

            <el-menu-item index="/case"><router-link to="/case">案例库</router-link></el-menu-item>

          </el-menu>
          <div class="toLink">
            <div v-if="isLogin">
              <router-link to="/login?type=login" class="toLogin">登录</router-link>
              <router-link to="/login?type=register" class="toRegister">注册</router-link>
            </div>
            <div v-else>
              <img class="avatar" :src="userInfo.avatar_url" alt="">
              <!-- <router-link to="" class="toAdmin"></router-link> -->
              <router-link to="/admin/index" type="info" :underline="false" style="padding: none;border: none;border-radius: none;font-size: 16px;">进入后台</router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <footer>
    </footer>
  </div>
</template>
<script>
  import { getUserApi, getUserLoginApi } from '@/api/login'
  export default {
    name: 'headPart',
		props: {
			hasBj: {
				type: Boolean,
				default: false
			},
		},
    data() {
      return {
        isLogin: true,
        isHome: this.$route.path === '/login',
        userInfo:'',
        activeIndex: '/'
      };
  },
  created () {
    this.setCurrentRoute()
    if (this.activeIndex.indexOf('/admin') != -1) {
      this.getUserLoginInfo()
    } else { 
      this.getUserInfo()
    }
	},
  watch: {
    $route () {
      this.setCurrentRoute()
    }
  },
    methods: {
      setCurrentRoute () {
        this.activeIndex = this.$route.path//关键   通过他就可以监听到当前路由状态并激活当前菜单
      },
      getUserInfo() { 
        let that = this
        
        getUserApi().then(res => { 
          if (res.code == 1) {
            that.userInfo = res.data
            if (res.data != '') {
              that.isLogin = false;
              localStorage.setItem("userInfo", JSON.stringify(res.data));
            } else {
              that.isLogin = true;
            }
          }
        })
      },
      getUserLoginInfo() { 
        let that = this
        getUserLoginApi().then(res => { 
          if (res.code == 1) {
            that.userInfo = res.data
            if (res.data != '') {
              that.isLogin = false;
              localStorage.setItem("userInfo", JSON.stringify(res.data));
            } else {
              that.isLogin = true;
            }
          }
        })
      }
    }
  }
</script>
<style scoped>
.header {
  position: relative;
  width: 100%;
  z-index: 99;
  background: transparent;
  transition: all .4s;
}
.header.hide {
  display: none;
}
.header header> .container {
  position: relative;
}
.header header > .container > a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  align-items: center;
}
.logo-yinqin {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.logo {
  position: relative;
}
.logo1 {
  max-height: 75px;
}
.logo2 {
  margin-left: 5px;
}
.header.isFixed {
  top: 0;
  left: 0;
  position: fixed;
}
.header.blue {
  background: #1A70FD;
}
header > .container {
  padding: 16px 0;
}
.el-menu-item {
  padding: 0;
}
.el-menu {
  background: none;
  height: auto !important;;
}
.el-dropdown-menu {
  width: 80px;
}
.el-dropdown-menu .el-dropdown-menu__item {
  padding: 0;
  text-align: center;
}
.el-dropdown-menu .el-dropdown-menu__item:hover a {
  color: #1A70FD;
}
.el-dropdown-menu .el-dropdown-menu__item a {
  color: #333;
}
.el-menu--horizontal>.el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  margin-left: 33px;
  color: #fff!important;
}
.el-menu--horizontal>.el-menu-item .el-dropdown-link a{
  opacity: .6;
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 6px;
}
.el-menu--horizontal>.el-menu-item a {
  display: block;
  opacity: .6;
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #fff;
  font-weight: bold;
}
.el-menu--horizontal>.el-menu-item.is-active a {
  opacity: 1;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background: none;
}
.el-menu--horizontal>.el-menu-item.is-active .el-dropdown-link a{
  color: #fff;
  opacity: 1;
}
.toLink {
  margin-left: 43px;
}

.toLink a {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  padding: 0 23px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  margin-left: 10px;
  transition: all .2s;
}
.toLink .toRegister:hover {
  background: none;
  color: #fff;
  opacity: 1;
}
.toLink .toRegister ,.toLink .toLogin:hover {
  background: #fff;
  color: #3A6BFF;
}
.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  vertical-align: middle;
}
a.toAdmin {
  padding: 0;
  border: none;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
</style>