import request from './axios'
// import { Message } from 'element-ui'

let myRequest = (function () {
    let hasRequest = [];
    return async function (config) { 
        let url = config.url
        if (hasRequest.indexOf(url) !== -1) { 
            // Message({
            //     message: '数据加载中' || 'Warning',
            //     type: 'warning',
            //     duration: 5 * 1000
            // })
        }
        hasRequest.push(url)
        const res = await request({ ...config });
        hasRequest = hasRequest.filter((item) => {
            if (item !== url) {
                return item;
            }
        });
        return res;
    }
 })() 

export {
    myRequest as request
}