<template>
  <div class="mainType">
    <div class="filter filter1"></div>
    <div class="filter filter2"></div>
		<div class="filter filter3"></div>
		<div class="filter filter4"></div>
		<div class="filter filter5"></div>
		<div class="filter filter6"></div>
		<div class="filter filter7"></div>
		<div class="filter filter8"></div>

    <div class="container ">
      <div class="part flex wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">
        <div class="part-item">
          <p><img src="../../../../static/images/home/part-1.svg" alt=""></p>
          <!-- <h2 class="font-22">内容撰写</h2> -->
          <h2 class="font-22">内容营销</h2>
          <p class="font-14 color-999">签约资深文案达人千名，帮企业创作有引爆点的深度内容</p>
        </div>
        <div class="part-item">
          <p><img src="../../../../static/images/home/part-2.svg" alt=""></p>
          <h2 class="font-22">媒体传播</h2>
          <p class="font-14 color-999">深度合作的网媒编辑达数千家，权重高，收录好</p>
        </div>
        <div class="part-item">
          <p><img src="../../../../static/images/home/part-3.svg" alt=""></p>
          <h2 class="font-22">记者邀约</h2>
          <p class="font-14 color-999">新闻发布会活动，邀请记者到场报道和高管专访</p>
        </div>
        <div class="part-item">
          <p><img src="../../../../static/images/home/part-4.svg" alt=""></p>
          <h2 class="font-22">舆情监测</h2>
          <p class="font-14 color-999">舆情发现、舆情预警、舆情分析、热搜监测、舆情报告</p>
        </div>
        <!-- <div class="part-item">
          <p><img src="../../../../static/images/home/part-5.svg" alt=""></p>
          <h2 class="font-22">搜索优化</h2>
          <p class="font-14 color-999">通过SEO优化，让品牌关键词在搜索平台有更好的展现</p>
        </div> -->
        <div class="part-item">
          <p><img src="../../../../static/images/home/part-5.svg" alt=""></p>
          <h2 class="font-22">网络优化</h2>
          <p class="font-14 color-999">通过网络优化，让品牌关键词在互联网上有更好的展现</p>
        </div>
      </div>
      <!-- 深度内容创作 -->
      <h2 class="title wow slideInUp" data-wow-duration='.5s' data-wow-delay="0s">深度内容营销</h2>
      <div class="createContent flex flex-content-around wow slideInUp" data-wow-duration='.5s' data-wow-delay="0.2s">
        <div class="item">
          <img src="../../../../static/images/home/zs.png" alt="">
          <span class="font-18">品牌故事</span>
        </div>
        <div class="item">
          <img src="../../../../static/images/home/kj.png" alt="">
          <span class="font-18">科技报道</span>
        </div>
        <div class="item">
          <img src="../../../../static/images/home/csr.png" alt="">
          <span class="font-18">创始人包装</span>
        </div>
        <div class="item">
          <img src="../../../../static/images/home/cp.png" alt="">
          <span class="font-18">产品文案</span>
        </div>
        <div class="item">
          <img src="../../../../static/images/home/pp.png" alt="">
          <span class="font-18">品牌案例</span>
        </div>
        <div class="item">
          <img src="../../../../static/images/home/rw.png" alt="">
          <span class="font-18">人物访谈</span>
        </div>
      </div>
      <!-- 文案天团 -->
			<h2 class="title wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">文案天团</h2>
			<h3 class=" wow slideInUp" data-wow-duration='1s' data-wow-delay="0.2s">4A文案大咖、BAT厂段子手、自媒体刷屏师、传媒记者老炮儿…</h3>
			<div class="textTeam flex flex-content-around wow slideInUp" data-wow-duration='1s' data-wow-delay="0.4s">

          <div class="item" v-for="(item, index) in writerList" :key="index">
            <span class="tag"></span>
            <div class="teamInfo flex flex-align-center">
              <img :src="item.avatar_url" alt="">
              <div>
                <h4 class="font-15">{{ item.name }}</h4>
                <span class="font-12 color-999">{{ item.label }}</span>
              </div>
            </div>
            <p class="font-14">{{ item.desc }}</p>
          </div>
          
        </div>
      <!-- Media Distribution 媒体分发 -->
			<div class="mediaDistribution flex wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">
				<div class="text-center">
					<img src="../../../../static/images/home/media.png" alt="">
				</div>
				<div>
					<h1>10W+媒体分发渠道</h1>
					<p>自助发稿平台操作高效</p>
					<p>最快1小时极速发布</p>
					<p>高性价比节约成本</p>
				</div>
			</div>
      <!-- 行业传播 -->
			<div class="Industry flex wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">
				<div class="text-right">
					<h1>行业传播套餐</h1>
					<div class="flex flex-content-around">
						<span>包收录套餐</span>
						<span>融资报道</span>
						<span>家居生活</span>
						<span>健康医疗</span>
						<span>食品快消</span>
						<span>商业财经</span>
						<span>IT科技</span>
						<span>母婴亲子</span>
						<span>汽车工业</span>
						<span>女性时尚</span>
						<span>旅游酒店</span>
						<span>品牌出海</span>
					</div>
				</div>
				<div class="text-center">
					<img src="../../../../static/images/home/Industry.png" alt="">
				</div>
			</div>
      <!-- 网络舆情监测 -->
      <h2 class="title wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">网络舆情监测</h2>
      <div class="Networkpublicopinion wow slideInUp" data-wow-duration='1s' data-wow-delay="0.2s">
          <div class="box">
            <img src="../../../../static/images/home/circle.svg" alt="">
            <div class="item item-1 flex">
              <div>
                <h4>舆情发现</h4>
                <p class="font-12 color-999">以全网舆情监测为核心，通过图片检索事件搜索、热搜监测、视频监测、社交关系挖掘等多重功能实现对全网舆情的实时发现</p>
              </div>
              <img src="../../../../static/images/home/ball-1.svg" alt="">
            </div>
            <div class="item item-2 flex">
              <div>
                <h4>舆情预警</h4>
                <p class="font-12 color-999">多渠道实时反馈预警、快速便捷7*24小时，舆情监测触发负面词多渠道自动、实时下发预警信息;人工实时预警</p>
              </div>
              <img src="../../../../static/images/home/ball-2.svg" alt="">
            </div>
            <div class="item item-3 flex">
              <div>
                <h4>舆情分析</h4>
                <p class="font-12 color-999">5类大数据分析，几十种数据维度满足政企客户多种分析需求</p>
              </div>
              <img src="../../../../static/images/home/ball-3.svg" alt="">
            </div>
            <div class="item item-4 flex">
              <img src="../../../../static/images/home/ball-4.svg" alt="">
              <div>
                <h4>舆情服务</h4>
                <p class="font-12 color-999">全网、全媒体要盖，7*24小时监测，实现对品牌、产品、重点事件、企业高管、竞品、政策及行业信息做到全网实时监测，对微博、抖音短视频等自媒体平台、口碑平台重点监测，帮助企业第一时间了解自身敏感信息、竞品、行业动态</p>
              </div>
            </div>
            <div class="item item-5 flex">
              <img src="../../../../static/images/home/ball-5.svg" alt="">
              <div>
                <h4>舆情报告</h4>
                <p class="font-12 color-999">多重报告自动生成模式，亦可定制专业化报告，平台自带行业舆情案例库，为舆情应对提供参考</p>
              </div>
            </div>
            <div class="item item-6 flex">
              <img src="../../../../static/images/home/ball-6.svg" alt="">
              <div>
                <h4>公关策略</h4>
                <p class="font-12 color-999">提高优化效率，赋能营销关键点，驱动营销效果最大化</p>
              </div>
            </div>
          </div>
        </div>
      <h2 class="title wow slideInUp" data-wow-duration='1s' data-wow-delay="0s">品牌合作案例</h2>
        <div class="brandCollaboration wow slideInUp" data-wow-duration='1s' data-wow-delay="0.2s">
          <el-row :gutter="20">

            <el-col :span="12" v-for="(item, index) in writerCaseList" :key="index">
              <div class="flex">
                <div class="img">
                  <img :src="item.path" alt="">
                </div>
                <ul>
                  <li v-for="(item, index) in item.count" :key="index"><a :href="item.path" target="_blank" rel="nofollow"  :underline="false">{{item.title}}</a></li>
                </ul>
              </div>
            </el-col>
           
          </el-row>
        </div>
    </div>
    <!-- 底部banner -->
    <div class="special text-center">
        <h1>发稿狮-用内容引爆品牌</h1>
        <p>
          <a href="https://jinshuju.net/f/cWNjKG" target="_blank" rel="nofollow"  class="hasBJ">媒体入驻</a>
          <a href="https://jinshuju.net/f/YYFbMm" target="_blank" rel="nofollow"  class="hasBD">代理合作</a>
        </p>
      </div>
  </div>
</template>
<script>
import { getWriterLisApi, getPinpaihezuo } from '@/api/home'
export default {
  name: 'mainType',
  data () {
    return {
      writerList: [],
      writerCaseList: []
    }
  },
  created () {
    this.getWriter()
    console.log(this.writerCaseList)
	},
	mounted () {
		new this.$wow.WOW({
			boxClass: 'wow',
			animateClass: 'animated',
			offset: 50,
			mobile: true,
			live: true
			}).init();
  },
  methods: {
    // 获取写手
    getWriter() {
      let that = this
      getWriterLisApi().then(
        res => { 
          that.writerList = res.data
        })

      getPinpaihezuo().then(
        res => { 
          that.writerCaseList = res.data
      })
    }
	}
  
}
</script>
<style scoped>
	@import url("../../../../static/css/animate.css");
.mainType {
	position: relative;
}
.filter {
  width: 333px;
  height: 460px;
  opacity: 0.1;
	filter: blur(100px);
	position: absolute;
	z-index: -1;
}
.filter.filter1 {
	background: #25C9FF;
	top: 5%;
	left: 30%;
}
.filter.filter2 {
	background: #FF741F;
	top: 10%;
	right: 0%;
}
.filter.filter3 {
	background: #FF8BFE;
	top: 22%;
	left: 0%;
}
.filter.filter4 {
	background: #31FFCD;
	top: 20%;
	left: 60%;
}
.filter.filter5 {
	background: #31FFCD;
	top: 37%;
	left: 18%;
}
.filter.filter6 {
	background: #FF8BFE;
	filter: blur(200px);
	top: 50%;
	right: 0%;
}
.filter.filter7 {
	background: #FF741F;
	filter: blur(100px);
	top: 55%;
	left: 0%;
}
.filter.filter8 {
	background: #25C9FF;
	filter: blur(100px);
	top: 55%;
	left: 50%;
}
.part {
  padding-top: 100px;
}
.part .part-item {
  padding: 0 40px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 18px rgba(184, 193, 208, 0.24);
  text-align: center;
  padding-bottom: 22px;
  width: 25%;
  margin-right: 15px;
  box-sizing: border-box;
}
.part .part-item:last-child {
  margin-right: 0;
}
.part .part-item h2 {
  margin-bottom: 14px;
}
.part .part-item img {
  width: 118px;
  height: 123px;
}
.part .part-item p {
  line-height: 22px;
  width: 150px;
  margin: 0 auto;
}

.container > .title {
  margin-top: 120px;
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
}
.container >  h3 {
  font-size: 15px;
  text-align: center;
  color: #666;
  margin-top: 30px;
  font-weight: normal;
}
.createContent {
  margin-top: 42px;
}
.createContent .item {
  background: #fff;
  border-radius: 30px;
  padding: 18px 32px;
  box-shadow: 0px 10px 18px 0px rgba(184,193,208,0.24);
  display: flex;
  align-items: center;
}
.createContent .item img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 5px;
}

.textTeam {
  flex-wrap: wrap;
  margin-top: 40px;
}
.textTeam .item {
  width: calc(33% - 70px);
  margin-right: calc(60px / 3);
  padding: 22px;
  background: #fff;
  box-shadow: 0px 10px 18px 0px rgba(184,193,208,0.24);
  margin-bottom: 25px;
  border-radius: 10px;
  position: relative;
}
.textTeam .item > p {
  color: #767676;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  line-height: 26px;
  margin-top: 15px;
}
.textTeam .item .tag {
  width: 33px;
  height: 32px;
  background: url(../../../../static/images/home/douhao.svg) no-repeat;
  position: absolute;
  top: 40px;
  right: 20px;
}
.teamInfo img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 12px;
}
.mediaDistribution, .Industry {
  margin-top: 120px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 10px 18px 0px rgba(184,193,208,0.24);
}
.mediaDistribution > div {
  width: 50%;
}
.Industry > div {
  width: 550px;
}
.mediaDistribution h1, .Industry h1 {
  font-size: 45px;
  margin-top: 70px;
  margin-bottom: 30px;
}
.mediaDistribution p {
  font-size: 22px;
  color: #2391FD;
  margin-bottom: 24px;
  position: relative;
  padding-left: 40px;
}
.mediaDistribution p:before {
  content: '';
  width: 30px;
  height: 30px;
  background: url(../../../../static/images/home/gou.png) no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
}
.Industry .flex {
  flex-wrap: wrap;
  padding-left: 80px;
  margin-top: 20px;
}
.Industry .flex span {
  display: inline-block;
  padding: 12px 0;
  width: 105px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  background: #EDF6FF;
  color: #2391FD;
  border-radius: 30px;
}
.Industry img {
  margin-left: 160px;
}
.Networkpublicopinion {
  text-align: center;
}
.Networkpublicopinion .box{
  position: relative;
  display: inline-block;
}
.Networkpublicopinion .box > img {
  width: 720px;
  height: 550px;
  margin-top: 70px;
  display: inline-block;
}
.Networkpublicopinion .item {
  width: 300px;
  position: absolute;
  text-align: right;
}
.Networkpublicopinion .item h4 {
  margin-bottom: 10px;
}
.Networkpublicopinion .item p {
  text-indent: 24px;
  line-height: 22px;
  width: 190px;
}
.Networkpublicopinion .item img {
  width: 95px;
  height: 100px;
  display: block;
}
.Networkpublicopinion .item-1 {
  top: 30px;
  left: -240px;
}
.Networkpublicopinion .item-2 {
  top: 320px;
  left: -240px;
}
.Networkpublicopinion .item-3 {
  top: 572px;
  left: -240px;
}
.Networkpublicopinion .item-4 {
  top: 30px;
  right: -240px;
  text-align: left;
}
.Networkpublicopinion .item-5 {
  top: 320px;
  right: -240px;
  text-align: left;
}
.Networkpublicopinion .item-6 {
  top: 572px;
  right: -240px;
  text-align: left;
}
.brandCollaboration  .flex .img {
  box-shadow: 0 2px 10px rgba(4, 107, 120, 0.1);
  border-radius: 10px;
  margin-right: 15px;
  overflow: hidden;
}
.brandCollaboration  .flex img {
  width: 197px;
  height: 124px;
  display: block;
  margin-right: 15px;
}
.brandCollaboration .flex {
  margin-top: 40px;
}
.brandCollaboration ul li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 345px;
  color: #000;
  height: 20px;
  font-size: 16px;
  margin: 14px 0;
}
.special {
  padding: 47px 0;
  background: url(../../../../static/images/home/home-f-banner.jpg) no-repeat;
  background-size: cover;
  background-position: center bottom;
  color: #fff;
  margin-top: 120px;
}
.special h1 {
  font-size: 38px;
  margin-bottom: 33px;
}
.special a {
  display: inline-block;
  padding: 15px 35px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #2391FD;
  margin: 0 10px;
  transition: all .2s;
}
.special a.hasBJ, .special a.hasBD:hover {
  background: #fff;
}
.special a.hasBD {
  color: #fff;
}
.special a.hasBJ:hover {
  background: none;
  color: #fff;
}
.special a.hasBD:hover {
  color: #2391FD;
}
</style>