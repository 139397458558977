import { request } from '@/utils/request'

// 获取网络媒体分类
export function getMediaType() {
  return request({
    url: '/index/get_media_get_type',
    method: 'get',
  })
}
// 获取网络媒体数据
export function getMediaList(data) {
  return request({
    url: '/index/get_media_list',
    method: 'post',
    data: {
      ...data
    }
  })
}

// 导出网络媒体数据
export function dcMediaList(data) {
  return request({
    url: '/index/get_media_list',
    method: 'post',
    responseType: 'arraybuffer',
    data: {
      ...data
    }
  })
}

// 获取自媒体分类
export function getZmtType() {
  return request({
    url: '/index/get_zmt_get_type',
    method: 'get',
  })
}

// 获取自媒体数据
export function getZmtList(data) {
  return request({
    url: '/index/get_zmt_list',
    method: 'post',
    data: {
      ...data
    }
  })
}

// 获取海外媒体分类
export function getHwmtType() {
  return request({
    url: '/index/get_hwmt_get_type',
    method: 'get',
  })
}
// 获取海外媒体数据
export function getHwmtList(data) {
  return request({
    url: '/index/get_hwmt_list',
    method: 'post',
    data: {
      ...data
    }
  })
}



