<template>
  <transition name="el-fade-in-linear">
    <div class="sideBar" v-show="showSideBar" :class="{'hide': this.$route.meta.hasNav}">
      <div class="distribute news">
        <router-link :to="'/'">发稿赠送写稿</router-link>
      </div>
      <div class="distribute outlink">
        <!-- <router-link :to="'/'" class="serve"></router-link> -->
        <router-link :to="'/'" class="tel">
            <div class="ewm">13585993094</div>
        </router-link>
        <router-link :to="'/'"></router-link>
        <router-link :to="'/'" class="wechat">
          <div class="ewm">
            <img src="../../static/images/home/ewm.png" alt="">
          </div>
        </router-link>
        <span @click="toTop" class="totop"></span>
      </div>
    </div>
</transition>

</template>
<script>
export default {
  name: 'sideBar',
  data () {
    return {
      showSideBar: false
    }
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.showSideBar = scrollTop > 300 ? true : false
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  }

}
</script>
<style scoped>
.sideBar {
  width: 50px;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}
.sideBar.hide {
  display: none;
}
.sideBar .serve {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url(../../static/images/home/serve.svg) no-repeat center;
}
.sideBar .serve:hover {
  background: url(../../static/images/home/serve2.svg) no-repeat center;
}
.sideBar .tel {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url(../../static/images/home/telphone.svg) no-repeat center;
}
.sideBar .tel:hover {
  background: url(../../static/images/home/tel2.svg) no-repeat center;
}

.sideBar .tel .ewm {
  padding: 20px 10px;
  background: #fff;
  position: absolute;
  width: 117px;
  right: 120%;
  top: 50%;
  color: #000;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  border-radius: 4px;
  display: none;
  transition: all .2s;
  opacity: 0;
  font-size: 15px;
}

.sideBar .tel:hover .ewm {
  display: block;
  opacity: 1;
}

.sideBar .qq {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url(../../static/images/home/qq.svg) no-repeat center;
}
.sideBar .qq:hover {
  background: url(../../static/images/home/qq2.svg) no-repeat center;
}
.sideBar .wechat {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url(../../static/images/home/wechat.svg) no-repeat center;
  position: relative;
}
.sideBar .wechat .ewm {
  padding: 10px;
  background: #fff;
  position: absolute;
  width: 117px;
  height: 117px;
  right: 120%;
  top: 50%;
  margin-top: -60px;
  color: #000;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  border-radius: 4px;
  display: none;
  transition: all .2s;
  opacity: 0;
  font-size: 12px;
}
.sideBar .wechat:hover .ewm {
  display: block;
  opacity: 1;
}
.sideBar .wechat:hover {
  background: url(../../static/images/home/wechat2.svg) no-repeat center;
}
.sideBar .totop {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url(../../static/images/home/totop.svg) no-repeat center;
}
.sideBar .totop:hover {
  background: url(../../static/images/home/totop2.svg) no-repeat center;
}
.sideBar .distribute {
  font-size: 16px;
  box-shadow: 0px 10px 18px 0px rgba(184,193,208,0.24);
  background: linear-gradient(180deg, #7294FF 0%, #2E54F5 100%);
  border-radius: 26px;
  text-align: center;
}
.sideBar .distribute.news a {
  width: 17px;
  color: #fff;
  display: inline-block;
  padding: 10px 18px;
}

.sideBar .distribute.outlink {
  background: #fff;
  margin-top: 18px;
}
.sideBar .distribute.outlink a,.sideBar .distribute.outlink span {
  width: 28px;
  height: 28px;
  color: #fff;
  display: block;
  padding: 10px 11px;
  cursor: pointer;
}
.sideBar .distribute.outlink a img {
  width: 100%;
}
</style>