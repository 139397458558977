import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import WOW from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import VueWechatTitle from 'vue-wechat-title';  
// 引入编辑器



Vue.use(VueWechatTitle)
Vue.use(ElementUI);

Vue.prototype.$wow = WOW
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
         window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
     }
  }
  window.scroll(0, 0); 
  next();
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
