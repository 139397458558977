import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
import MediaRepository from '../views/MediaRepository'
import Team from '../views/Team/index.vue'
import CaseBase from '../views/CaseBase'
import CaseBaseDetail from '../views/CaseBase/caseBaseDetail'
import articleDetail from '../views/CaseBase/articleDetail'


Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: '/',
  routes: [
    {
      path: '/admin/index',
      name: 'adminLayout',
      redirect: '/admin/personal',
      component: () => import('../views/Admin/Home/layout.vue'),
      meta: {
        hasNav: true,
        title: '后台管理'
      },
      children: [
        {
          path: '/admin/personal',
          name: 'personalIndex',
          component: () => import('../views/Admin/Personal/index.vue'),
          meta: {
            hasNav: true,
            title: '个人中心'
          },
        },
        {
          path: '/admin/notify',
          name: 'notifyCenter',
          component: () => import('../views/Admin/Home/notify.vue'),
          meta: {
            hasNav: true,
            title: '消息中心'
          },
        },
        {
          path: '/admin/media',
          name: 'netMedia',
          component: () => import('../views/Admin/MediaRepository/media.vue'),
          meta: {
            hasNav: true,
            title: '网络媒体'
          },
        },
        {
          path: '/admin/zmt',
          name: 'zmtMedia',
          component: () => import('../views/Admin/MediaRepository/zmt.vue'),
          meta: {
            hasNav: true,
            title: '自媒体'
          },
        },
        {
          path: '/admin/hwmt',
          name: 'hwmtMedia',
          component: () => import('../views/Admin/MediaRepository/hwmt.vue'),
          meta: {
            hasNav: true,
            title: '海外媒体'
          },
        },
        {
          path: '/admin/publish',
          name: 'wordPublish',
          component: () => import('../views/Admin/Publish/index.vue'),
          meta: {
            hasNav: true,
            title: '发布文章'
          },
        },
        {
          path: '/admin/detail',
          name: 'wordDetail',
          component: () => import('../views/Admin/Publish/detail.vue'),
          meta: {
            hasNav: true,
            title: '文章预览'
          },
        },
        {
          path: '/admin/newsOrder',
          name: 'newsOrder',
          component: () => import('../views/Admin/Publish/newsOrder.vue'),
          meta: {
            hasNav: true,
            title: '发布列表'
          }
        },
        {
          path: '/admin/newsDraft',
          name: 'newsDraft',
          component: () => import('../views/Admin/Publish/newsDraft.vue'),
          meta: {
            hasNav: true,
            title: '草稿箱'
          },
        },
        {
          path: '/admin/recharge',
          name: 'rechargeMoney',
          component: () => import('../views/Admin/RechargeCenter/recharge.vue'),
          meta: {
            hasNav: true,
            title: '充值'
          },
        },
        {
          path: '/admin/invoiceSet',
          name: 'invoiceSet',
          component: () => import('../views/Admin/RechargeCenter/invoiceSet.vue'),
          meta: {
            hasNav: true,
            title: '发票'
          },
        },
        {
          path: '/admin/invoiceSetInfo/:id',
          name: 'invoiceSetInfo',
          component: () => import('../views/Admin/RechargeCenter/invoiceSetInfo.vue'),
          meta: {
            hasNav: true,
            title: '发票详情'
          },
        },
        {
          path: '/admin/userBill',
          name: 'userBill',
          component: () => import('../views/Admin/RechargeCenter/userBill.vue'),
          meta: {
            hasNav: true,
            title: '消费记录'
          },
        },
        {
          path: '/admin/setProfile',
          name: 'setProfile',
          component: () => import('../views/Admin/Setting/setProfile.vue'),
          meta: {
            hasNav: true,
            title: '修改资料'
          },
        },
        {
          path: '/admin/setPassword',
          name: 'setPassword',
          component: () => import('../views/Admin/Setting/setPassword.vue'),
          meta: {
            hasNav: true,
            title: '修改密码'
          },
        }
      ]
    },
    {
      path: '/login',
      name: 'LoginIndex',
      component: () => import('../views/Login/index.vue'),
      meta: {
        hideHeader: true,
        title: '发稿狮-登录'
      }
    },
    {
      path: '/',
      name: 'HomeIndex',
      component: Home,
      meta: {
        isBlueBj: false,
        title: '发稿狮'
      }
    },
    {
      path: '/media',
      name: 'MediaRepository',
      component: MediaRepository,
      meta: {
        isBlueBj: true,
        title: '发稿狮-媒体资源'
      }
    },
    {
      path: '/zmt',
      name: 'ZiMeiTi',
      component: () => import('../views/MediaRepository/zmt.vue'),
      meta: {
        isBlueBj: true,
        title: '发稿狮-自媒体'
      }
    },
    {
      path: '/hwmt',
      name: 'HaiWaiMeiTi',
      component: () => import('../views/MediaRepository/hwmt.vue'),
      meta: {
        isBlueBj: true,
        title: '发稿狮-海外媒体'
      }
    },
    {
      path: '/team',
      name: 'teamPart',
      component: Team,
      meta: {
        isBlueBj: true,
        title: '发稿狮-文案天团'
      }
    }, {
      path: '/teamDetail',
      name: 'teamDetail',
      component: () => import('../views/Team/teamDetail.vue'),
      meta: {
        isBlueBj: true,
        title: '发稿狮-案例库'
      }
    },
    {
      path: '/case',
      name: 'caseBase',
      component: CaseBase,
      meta: {
        isBlueBj: true,
        title: '发稿狮-写手详情'
      }
    },
    {
      path: '/caseDetail',
      name: 'caseBaseDetail',
      component: CaseBaseDetail,
      meta: {
        isBlueBj: true,
        title: '发稿狮-案例详情'
      }
    },
    {
      path: '/articleDetail',
      name: 'articleDetail',
      component: articleDetail,
      meta: {
        hideHeader: true,
        isBlueBj: true,
        title: '发稿狮-文章详情'
      }
    },
    {
      path: '/news',
      name: 'newsBase',
      component: () => import('../views/News/index.vue'),
      meta: {
        isBlueBj: true,
        title: '公关学堂'
      }
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: () => import('../views/News/newsDetail.vue'),
      meta: {
        isBlueBj: true,
        title: '公关学堂-文章详情'
      }
    }
  ]
})

