<template>
  <div id="app">
    <HeadPart v-if="!$route.meta.hideHeader" :hasBj.sync="hasBj" :activeIndex="activeIndex"/>
    <sideBar />
    <router-view v-wechat-title="$route.meta.title + '—新闻发稿|软文发稿|新闻源媒体发布平台'"></router-view>
    <FootPart v-if="!$route.meta.hideHeader" />
  </div>
</template>

<script>
import HeadPart from './components/Header.vue'
import FootPart from './components/Footer.vue'
import sideBar from './components/sideBar.vue'

export default {
  name: 'App',
  components: {
    HeadPart,
    FootPart,
    sideBar
  },
	data () {
		return {
			hasBj: false,
			activeIndex: '/'
		}
	},
	created () {
	  this.handleScroll()
	}, 
	mounted () {
	  window.addEventListener("scroll", this.handleScroll);//监听滑动事件
	},
	methods: {
		handleScroll () {
		  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		  this.hasBj = scrollTop > 0 ? true : false
		}
	},
	watch: {
	  '$route.path' (n) {
			this.hasBj = false
	    this.activeIndex = n
	    if (n === '/home') {
	      this.hasBj = false
	    } else {
	      this.hasBj = true
	    }
	  }
	},
}
</script>

<style>
@import "../static/css/layout.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-tooltip__popper.is-light {
  border: 1px solid #eee!important;
  box-shadow: 0px 9px 16px 0px rgba(0,0,0,0.1)!important;
	width: 300px;
	white-space: wrap;
	line-height: 25px;
	color: #333;
	font-size: 14px;
	font-family: pingfang;
}
.el-tooltip__popper .popper__arrow {
  border: none!important;
}
* img{
  max-width: 100%!important
}
</style>
