import { request } from '@/utils/request'

// 发稿
export function gePublishApi(data) {
    return request({
        url: '/index/publish',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 获取发稿列表
export function geOrderListApi(data) {
    return request({
        url: '/index/get_order_list',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 导出发稿列表
export function geDaoChuOrderListApi(data) {
    return request({
        url: '/index/get_daochu_order_list',
        method: 'post',
        responseType: 'arraybuffer',
        data: {
            ...data
        }
    })
}



// 获取改稿信息
export function geGaiGaoListApi(data) {
    return request({
        url: '/index/get_gaigao_list',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 申请改稿
export function geGaiGaoApi(data) {
    return request({
        url: '/index/get_gaigao',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 申请改稿
export function geTuiGaoApi(data) {
    return request({
        url: '/index/get_tuigao',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 草稿详情
export function getGaoJianInfoApi(data) {
    return request({
        url: '/index/get_gaojian_info',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 申请改稿
export function delGaoJianApi(data) {
    return request({
        url: '/index/del_gaojian',
        method: 'post',
        data: {
            ...data
        }
    })
}

// 充值订单
export function setOrderApi(data) {
    return request({
      url: '/index/create_order',
      method: 'post',
      data: {
        ...data
      }
    })
}

// 充值订单查询
export function getOrderApi(data) {
    return request({
      url: '/index/get_pay_order',
      method: 'post',
      data: {
        ...data
      }
    })
}