<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: path }">{{title}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{title}}详情</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  name: 'breadcrumbPart',
  props: {
    title: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  data () {
    return {
      
    }
  }
}
</script>
<style scoped>
.el-breadcrumb {
  padding: 20px 0;
}
</style>