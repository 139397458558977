import axios from 'axios'
import { Message } from 'element-ui'

import configs from './config'

let baseURL = '';

if(process.env.VUE_APP_ENV === 'dev') {
    baseURL = '';
} else {
    baseURL = process.env.VUE_APP_BASE_URL
}

const request = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    // headers: {'X-Custom-Header': 'foobar'}
  });

// 添加请求拦截器
request.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    let whiteList = configs.whiteListApi
    if (whiteList.indexOf(config.url) !== -1) {
        config.headers.token = localStorage.getItem("token")??'',
        config.headers.refresh_token =localStorage.getItem("refresh_token")??''
    }
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use((response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code == 1) {
        if (response.data.data.token) {
            localStorage.setItem("token", response.data.data.token);
        }
        if (response.data.data.refresh_token) {
            localStorage.setItem("refresh_token", response.data.data.token);
        }
        return response.data;
    } else if(response.data.code == 302) {
        window.location.href = 'https://m.fagaoshi.com/#/'
        return
    } else if (response.data.code == 7) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/'
    } else {
        // Message({
        //     message: response.data.msg || 'Error',
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return response.data;
    }
}, error => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    return Promise.reject(error);
});

export default request;