<template>
  <div class="textTeam">
    <div class="container">
      <div class="pad checkArea">
        <div class="flex">
          <span class="typeName font-20">类型</span>
          <el-radio-group v-model="params.type" @change="handleTypeChange">
            <el-radio v-for="item in goodAtTypeArray" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="flex">
          <span class="typeName font-20">行业</span>
          <el-radio-group v-model="params.ind" @change="handleIndChange">
            <el-radio v-for="item in industryArray" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="pad" v-for="item in writerList" :key="item.value" @click="toPath(item.id)" style="cursor : pointer;">
        <div class="list ">
          <div class="left">
            <div class="user flex flex-align-center">
              <img :src="item.avatar_url" alt="">
              <div>
                <h3>{{item.name}}</h3>
                <div class="tag-group">
                  <span v-for="v in item.ind_id" :key="v.value">{{v.name}}</span>
                  <span v-for="v in item.tag" :key="v.value">{{v.name}}</span>
                </div>
              </div>
            </div>
            <p>{{item.desc}}</p>
          </div>
          <div class="right">
            <ul>
              <li><router-link :to="{ path: '/caseDetail', query: {id:v.id} }" v-for="v in item.card" :key="v.value">{{v.title}}</router-link></li>
            </ul>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleChangeChange"
        :page-size="params.pageSize"
        :total="writerCount">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getWriterApi, getWriterClassApi } from '@/api/home'
export default {
  name: 'teamPart',
  data () {
    return {
      params: {
        pageIndex: 1,
        pageSize: 15,
        ind: 1,
        type: 1
      },
      writerList: [],
      writerCount: 1,
      writerCaseList: [],
      goodAtTypeArray: [],
      industryArray: [],
    }
  },
  created () {
    this.getWriter()
  },
  
  methods: {
    // 获取写手
    getWriter() {
      let that = this
      getWriterApi(that.params).then(
        res => { 
          that.writerList = res.data.list
          that.writerCount = res.data.count
          
        })
      getWriterClassApi().then(
        res => { 
          that.goodAtTypeArray = res.data.writer_case_type
          that.industryArray = res.data.writer_case_ind
        })
    },
    handleChangeChange(value)
    {
      this.params.pageIndex = value
      this.getWriter()
    },
    handleTypeChange(value)
    {
      this.params.type = value
      this.getWriter()
    },
    handleIndChange(value)
    {
      this.params.ind = value
      this.getWriter()
    },
    toPath(id) { 
      this.$router.push({path:'/teamDetail',query:{id:id}});
    }
    
	}
}
</script>
<style scoped>
.textTeam {
  background: #EFF7FF;
  padding-top: 86px;
  padding-bottom: 40px;
}
.textTeam .pad {
  padding: 20px 25px;
  padding-bottom: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(184, 193, 208, 0.24);
  margin-bottom: 12px;
}
.textTeam .typeName {
  width: 120px;
  display: block;
  font-weight: bold;
  padding-top: 3px;
}

/deep/.el-radio__inner {
  display: none;
}
/deep/.el-radio {
  padding: 10px 15px;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 16px;
  margin-right: 8px;
}
/deep/.el-radio-group {
  width: 1180px;
}
/deep/.el-radio__label {
  padding-left: 0;
}

/deep/.el-radio:hover{
  background: #EFF7FF;
  color: #1A70FD;
}
/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #fff;
}
/deep/.el-radio.is-checked,/deep/.el-radio__input.is-checked:hover+.el-radio__label  {
  background: #1A70FD;
}
.list {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.list .left {
  border-right: 1px solid #F0F0F0;
  margin-bottom: 20px;
  width: 720px;
  padding-right: 40px;
}
.list .user img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}
.list .user .tag-group {
  margin-top: 12px;
}
.list .user span {
  padding: 7px 9px;
  border: 1px solid #1E85F6;
  background: #EAF4FF;
  border-radius: 30px;
  font-size: 12px;
  color: #1E85F6;
  margin-right: 10px;
}
.list .left p {
  color: #959595;
  line-height: 24px;
  font-size: 14px;
  margin-top: 16px;
}
.list .right {
  width: 340px;
  padding-left: 30px;
  padding-bottom: 20px;
}

.list ul li + li {
  margin-top: 15px;
}
.list ul li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 345px;
  color: #000;
  height: 20px;
  margin: 15px 0;
  font-size: 14px;
}
.list ul li a:hover {
  color: #1E85F6;
}
.el-pagination {
  padding: 40px 0;
  text-align: center;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev, /deep/.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>