<template>
  <div>
		<Slider />
		<Main />
  </div>
</template>
<script>
import Slider from './components/sliderPart.vue'
import Main from './components/mainType.vue'
export default {
	name: 'homeIndex',
	components: {
		Slider,Main
	}
}
</script>
<style scoped>

</style>