<template>
    <div class="bodys" v-if="info !== null">
        <div>
          <div>
            <h3>{{info.title}}</h3>
          </div>
          <div class="content" v-html="info.content">
          </div>
        </div>
    </div>
</template>
<script>
import { getGaoJianInfoApi } from '@/api/order'
export default {
  name: 'articleDetail',
  data () {
    return {
      isCollected: false,
      id: '',
      info: null
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getWriterInfo()
  },
  activated () {
    this.getWriterInfo()
  },
  methods: {
    // 获取写手
    getWriterInfo() {
      let that = this
      getGaoJianInfoApi({ id:that.id }).then(res => {
        that.info = res.data
        document.title = this.info.title + '详情-发稿狮|企业新闻公关服务平台_媒体传播就找发稿狮'
      })
    },
    toPath(url,data) { 
      this.$router.push({path:url,query:data});
    }
	}
}
</script>
<style scoped>
.bodys{
  width: 800px;
  margin: 0 auto;
}

.bodys div h3{
  text-align: center;
  line-height: 80px;
  border-bottom:1px solid #ddd;
  margin-bottom: 10px;
}

.bodys div{
  width: 100%;
  max-width: 100%;
}

.bodys .content{
  line-height: 2em;
  text-indent: 2em;
}

.content p{
  color:red
}


</style>