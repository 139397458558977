<template>
  <footer class="foot" :class="{'hide': this.$route.meta.hasNav}">
    <div class="container">
      <div class="flex">
        <div class="left">
          <img src="../../static/images/home/foot-logo.svg" alt="">
          <div class="text">
            <p>发稿狮创办于2014年，总部位于上海。发稿狮为企业提供专业的互联网PR服务，服务内容包含制定专业的PR策略、深度内容创作、媒体发布、舆情监测管理、搜索平台优化等全方位服务，并自主研发了企业自助发稿平台。</p>
            <p>发稿狮成立以来累计服务品牌企业超过3000+，行业覆盖互联网、房产、汽车、医药、金融、家居、母婴、美妆、快消、B2B、科技制造等，助力企业PR在互联网时代乘风破浪。</p>
          </div>
          <p><i></i> 客服电话：<span>135 8599 3094</span> 上海市银山路183号鑫桥创意园6号楼2层</p>
        </div>
        <div class="right flex">
          <div>
            <img src="../../static/images/home/ewm.png" alt="">
            <p>微信客服</p>
          </div>
          <div>
            <img src="../../static/images/home/qwkf.png" alt="">
            <p>微信客服</p>
          </div>
          <div>
            <img src="../../static/images/home/xcx.png" alt="">
            <p>小程序</p>
          </div>
        </div>
      </div>
    </div>
    <p class="copy">
      发稿狮 © 2023 ALL RIGHT RESERVED &nbsp; &nbsp;&nbsp;&nbsp;  <el-link href="https://beian.miit.gov.cn/" target="_blank"  :underline="false">沪ICP备12015044号-7</el-link> &nbsp; &nbsp;&nbsp;&nbsp;<router-link to="/news" style="color:#606266">公关学堂</router-link>
    </p>
  </footer>
</template>
<script>
  export default {
    name: 'footPart',
    data() {
      return {
        activeIndex: '1',
      };
    },
    created () {
      console.log(this.$route)
    },  
    methods: {
   
    }
  }
</script>
<style scoped>
.foot {
  padding-top: 50px;
  background: #fff;
}
.foot .left {
  width: 600px;
}
.foot.hide {
  display: none;
}
.foot .left img {
  display: inline-block;
  margin-bottom: 24px;

}
.foot .left .text p {
  font-size: 14px;
  line-height: 24px;
  color: #999;
  text-align: justify;
  margin-bottom: 15px;
}
.foot .left > p {
  margin-top: 10px;
  color: #999;
  font-size: 14px;
}
.foot .left > p i {
  width: 15px;
  height: 15px;
  background: url(../../static/images/login/phone.svg) no-repeat;
  display: inline-block;
  vertical-align: middle;
}
.foot .left > p span {
  color: #333;
}
.foot .right {
  margin-left: 215px;
  margin-top: 64px;
  text-align: center;
}
.foot .right div {
  margin-right: 18px;
}
.foot .right div p {
  margin-top: 10px;
}
.foot .copy {
  background: #E9EFF5;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #778794;
  margin-top: 50px;
}
</style>