import { request } from '@/utils/request'

export function LoginApi(data) {
  return request({
    url: '/index/login_pwd',
    method: 'post',
    data
  })
}

export function msgLoginApi(data) {
  return request({
    url: '/index/dx_login',
    method: 'post',
    data
  })
}

export function RegisterApi(data) {
  return request({
    url: '/index/do_register',
    method: 'post',
    data
  })
}


export function doResetApi(data) {
  return request({
    url: '/index/do_reset',
    method: 'post',
    data
  })
}

export function getSendCodeApi(data) {
  return request({
    url: '/index/api_send_code',
    method: 'post',
    data
  })
}

export function getUserApi() {
  return request({
    url: '/index/get_user',
    method: 'get',
  })
}

export function getUserLoginApi() {
  return request({
    url: '/index/get_user_login',
    method: 'get',
  })
}

export function getOurLoginApi() {
  return request({
    url: '/index/out_login',
    method: 'get',
  })
}

