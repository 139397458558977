<template>
  <div>
    <div class="slider wow">
      <el-carousel trigger="click" height="767px" arrow="never">
        <el-carousel-item class="slider-item slider-item-1">
          <div class="content container">
            <div class="text slide-in-fwd-left">
              <h1 class="color-white font-50">企业公关稿  就找发稿狮</h1>
              <h4 class="color-white fw-normal font-16"><span class="mg-right-15">10w+媒体分发渠道</span><span>用内容为品牌赋能</span>   </h4>
              <el-button class="btn font-22" size="default" @click="toLink('/login', 'login')">立即体验</el-button>
            </div>
						<img src="../../../../static/images/home/banner01.png" alt="" class="img img01 slide-in-fwd-br">
						<img src="../../../../static/images/home/banner02.png" alt="" class="img img02 slide-in-fwd-bl" />
						<img src="../../../../static/images/home/banner03.png" alt="" class="img img03 slide-in-fwd-bl">
						<img src="../../../../static/images/home/banner04.png" alt="" class="img img04 slide-in-fwd-br">
						<img src="../../../../static/images/home/banner05.png" alt="" class="img img05 fade-in-tl">
						<img src="../../../../static/images/home/banner06.png" alt="" class="img img06 fade-in-bottom">
						<img src="../../../../static/images/home/banner07.png" alt="" class="img img07 fade-in-right">
          </div>
        </el-carousel-item>
				<el-carousel-item class="slider-item slider-item-2">
          <div class="content container">
            <div class="text slide-in-fwd-left">
              <h1 class="color-white font-50">1000+签约资深文案 <br />召之即用</h1>
              <h4 class="color-white fw-normal font-16"><span class="mg-right-15">4A广告文案总监</span> <span class="mg-right-15">传统媒体主编</span><span class="mg-right-15">BAT大厂段子手</span><span class="mg-right-15">自媒体爆款制造机</span></h4>
              <el-button class="btn font-22" size="default" @click="toLink('/login', 'login')">立即体验</el-button>
            </div>
						<div class="fade-in-bottom">
							<img src="../../../../static/images/home/banner002.png" alt="" class="img01">
						</div>
          </div>
        </el-carousel-item>
				<el-carousel-item class="slider-item slider-item-2">
          <div class="content container">
            <div class="text slide-in-fwd-left">
              <h1 class="color-white font-50">网络舆情监测 <br />搜索口碑优化</h1>
              <h4 class="color-white fw-normal font-16"><span class="mg-right-15">为品牌互联网形象保驾护航</span></h4>
              <el-button class="btn font-22" size="default" @click="toLink('/login', 'login')">立即体验</el-button>
            </div>
						<div class="fade-in-bottom">
							<img src="../../../../static/images/home/banner003.png" alt="" class="img01">
						</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  
</template>
<script>
export default {
  name: 'sliderPart',
	data () {
		return {}
	},

	methods: {
		toLink(path, type) { 
			this.$router.push({path:path,query:{type:type}});
		}
	},
	mounted() { 

	}
}
</script>
<style scoped>
.slider {
  height: 767px;
}

.el-carousel .content {
  height: 100%;
  position: relative;
}
.el-carousel .content .text {
	position: absolute;
	top: 213px;
}
.el-carousel .content h1 {
  margin-bottom: 16px;
	text-align: left;
}
.el-carousel .content .btn {
  padding: 14px 38px;
  color: #2390FD;
  margin-top: 46px;
  border: none;
  width: auto;
}
.slider-item {
  background-position: center;
  padding-top: 39px;
  box-sizing: border-box;
  
}
.slider-item .flex {
	position: relative;
}
.slider-item-1 {
  background: url('../../../../static/images/home/banner_bj.jpg') no-repeat;
  background-size: cover;
}
.slider-item-2 {
  background: url('../../../../static/images/home/banner_bj2.jpg') no-repeat;
  background-size: cover;
}
.slider-item-2 .img01 {
	position: absolute;
	right: -130px;
	top: 50px;
}
.slider-item-2 .right {
	transform: translateY(-50%);
}
.slider .img {
	position: absolute;
}
.slider .img.img01 {
	right: -85px;
	top: 70px;
}
.slider .img.img02 {
  top: 70px;
  right: 140px;
}
.slider .img.img03 {
  bottom: 111px;
  right: 305px;
}
.slider .img.img04 {
  bottom: 195px;
  right: -25px;
}
.slider .img.img05 {
  top: 330px;
  right: 550px;
}
.slider .img.img06 {
  bottom: 170px;
  right: 290px;
}
.slider .img.img07 {
  top: 180px;
  right: -15px;
}

/deep/.el-carousel__indicator--horizontal {
  padding: 0 4px;
}
/deep/.el-carousel__button {
  height: 5px!important;
	width: 28px;
	border-radius: 3px;
}
/deep/.el-carousel__indicators--horizontal {
	bottom: 40px;
}
.slide-in-fwd-left {
		animation-name: slide-in-fwd-left;
		animation-duration: 1s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: slide-in-fwd-left 1s ease 0s 1 normal none;*/
	}
	@keyframes slide-in-fwd-left {
			
		0% {
			transform:translateZ(-1400px) translateX(-1000px);
			opacity:0;
		}
		100% {
			transform:translateZ(0) translateX(0);
			opacity:1;
		}
	}
  .slide-in-fwd-right {
		animation-name: slide-in-fwd-right;
		animation-duration: .5s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: slide-in-fwd-right 1s ease 0s 1 normal none;*/
	}
	@keyframes slide-in-fwd-right {
			
		0% {
			transform:translateZ(-1400px) translateX(1000px);
			opacity:0;
		}
		100% {
			transform:translateZ(0) translateX(0);
			opacity:1;
		}
	}
  .slide-in-top {
		animation-name: slide-in-top;
		animation-duration: .5s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: slide-in-top 1s ease 0s 1 normal none;*/
	}
	@keyframes slide-in-top {
			
		0% {
			transform:translateY(-1000px);
			opacity:0;
		}
		100% {
			transform:translateY(0);
			opacity:1;
		}
	}
  .slide-in-fwd-bl {
		animation-name: slide-in-fwd-bl;
		animation-duration: .5s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: slide-in-fwd-bl 1s ease 0s 1 normal none;*/
	}
	@keyframes slide-in-fwd-bl {
			
		0% {
			transform:translateZ(-1400px) translateY(800px) translateX(-1000px);
			opacity:0;
		}
		100% {
			transform:translateZ(0) translateY(0) translateX(0);
			opacity:1;
		}
	}
  .slide-in-fwd-br {
		animation-name: slide-in-fwd-br;
		animation-duration: .5s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: slide-in-fwd-br 1s ease 0s 1 normal none;*/
	}
	@keyframes slide-in-fwd-br {
			
		0% {
			transform:translateZ(-1400px) translateY(800px) translateX(1000px);
			opacity:0;
		}
		100% {
			transform:translateZ(0) translateY(0) translateX(0);
			opacity:1;
		}
	}
  .fade-in-tl {
		animation-name: fade-in-tl;
		animation-iteration-count: infinite;
		animation-duration: 2s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: fade-in-tl 1s ease 0s 1 normal none;*/
	}
	@keyframes fade-in-tl {
			
	  0% {
			transform:translateX(0) translateY(0);
		}
		50% {
			transform:translateX(-10px) translateY(-10px);
		}
		100% {
			transform:translateX(0) translateY(0);
		}
	}
  .fade-in-bottom {
		animation-name: fade-in-bottom;
		animation-duration: 2s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: fade-in-bottom 1s ease 0s 1 normal none;*/
	}
	@keyframes fade-in-bottom {
		0% {
			transform:translateY(0);
		}
		50% {
			transform:translateY(10px);
		}
		100% {
			transform:translateY(0);
		}
	}
  .fade-in-right {
		animation-name: fade-in-right;
		animation-duration: 2s;
		animation-timing-function: ease;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: fade-in-right 1s ease 0s 1 normal none;*/
	}
	@keyframes fade-in-right {
		0% {
			transform:translateX(0);
		}
		50% {
			transform:translateX(10px);
		}
		100% {
			transform:translateX(0);
		}
	}
</style>