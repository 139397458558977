<template>
  <div class="caseBaseDetail">
    <div class="container" v-if="info !== null">
      <breadcrumbPart :title="'案例库'" :path="'/case'"/>
      <div class="flex flex-content-between">
        <div class="left">
          <div class="topTitle">
            <!-- <div class="collectBtn" :class="{'collected': isCollected}" @click="isCollected = !isCollected">
              <i class="collect"></i> 收藏
            </div> -->
            <h3>{{info.title}}</h3>
            <p class="tag-group">
              <span v-for="v in info.ind" :key="v.value">{{v.name}}</span>
              <span v-for="v in info.type" :key="v.value">{{v.name}}</span>
            </p>
          </div>
          <div class="content" v-html="info.desc">
          </div>
        </div>
        <div class="right text-center">
          <div class="pad">
            <img :src="info.writer[0].avatar_url" alt="">
            <h4>{{ info.writer[0].name }}</h4>
            <!-- <p>XXXXXXXXXX公司</p> -->
            <!-- <p>高级经理</p> -->
            <el-button type="primary" size="default" @click="toPath('/teamDetail', {id:info.writer[0].id})">查看主页</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import breadcrumbPart from '../../components/breadcrumbPart.vue'
import { getWriterCaseInfoApi } from '@/api/home'
export default {
  name: 'caseBaseDetail',
  components: {
    breadcrumbPart
  },
  data () {
    return {
      isCollected: false,
      id: '',
      info: null
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getWriterInfo()
  },
  activated () {
    this.getWriterInfo()
  },
  methods: {
    // 获取写手
    getWriterInfo() {
      let that = this
      getWriterCaseInfoApi({id:that.id}).then(
        res => { 
          that.info = res.data
          document.title = this.info.title + '详情-发稿狮|企业新闻公关服务平台_媒体传播就找发稿狮'
        })
    },
    toPath(url,data) { 
      this.$router.push({path:url,query:data});
    }
	}
}
</script>
<style scoped>
.caseBaseDetail {
  background: #EFF7FF;
  padding-top: 86px;
  padding-bottom: 40px;
}
.caseBaseDetail .left {
  width: 905px;
  background: #fff;
  border-radius: 10px;
}

.topTitle {
  border-bottom: 1px solid #ededed;
  padding: 24px;
  padding-bottom: 15px;
  position: relative;
}
.topTitle h3 {
  margin-bottom: 18px;
}
.topTitle p {
  height: 30px;
}
.topTitle span {
  padding: 7px 9px;
  border: 1px solid #1E85F6;
  background: #EAF4FF;
  border-radius: 30px;
  font-size: 12px;
  color: #1E85F6;
  margin-right: 10px;
  display: inline-block;
}
.content {
  padding: 15px 24px;
}
.content img {
  border-radius: 10px;
  margin-bottom: 16px;
}
.content .text p{
  font-size: 16px;
  line-height: 26px;
  color: #333;
}
.collectBtn {
  padding: 7px 13px;
  border: 1px solid #B8B8B8;
  position: absolute;
  border-radius: 30px;
  top: 24px;
  right: 20px;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.collectBtn .collect {
  width: 18px;
  display: inline-block;
  margin-bottom: -5px;
  height: 18px;
  background: url(../../../static/images/home/uncollect.svg) no-repeat;
}
.collectBtn.collected {
  color: #1E85F6;
}
.collectBtn.collected .collect {
  background: url(../../../static/images/home/collect.svg) no-repeat;
}
.caseBaseDetail .right {
  width: 285px;
}
.caseBaseDetail .right img {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 10px;
}
.caseBaseDetail .right p {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.right .el-button {
  display: block;
  margin-top: 30px;
  width: 100%;
}
</style>