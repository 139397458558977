<template>
  <div class="caseBase">
    <div class="container">
      <div class="pad checkArea">
        <div class="flex">
          <span class="typeName font-20">擅长类型</span>
          <el-radio-group v-model="params.type" @change="handleTypeChange">
            <el-radio v-for="item in goodAtTypeArray" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="flex">
          <span class="typeName font-20">擅长行业</span>
          <el-radio-group v-model="params.ind" @change="handleIndChange">
            <el-radio v-for="item in industryArray" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="pad" v-for="item in list" :key="item.value" @click="toPath(item.id)" style="cursor : pointer;">
        <div class="news flex flex-align-center">
          <router-link to="/caseDetail">
            <el-image :src="item.avatar_url" v-if="item.avatar_url">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </router-link>
          <div>
            <div>
              <h3>{{item.title}}</h3>
              <router-link class="tag-group" :to="{ path: '/teamDetail', query: { id: item.writer[0].id } }">
                <img :src="item.writer[0].avatar_url" alt="">
                <h6>{{item.writer[0].name}}</h6>
                <span v-for="v in item.ind" :key="v.value">{{v.name}}</span>
                <span v-for="v in item.type" :key="v.value">{{v.name}}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleChangeChange"
        :page-size="params.pageSize"
        :total="count">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getWriterClassApi, getWriterCaseApi } from '@/api/home'
export default {
  name: 'caseBase',
  data () {
    return {
      params: {
        pageIndex: 1,
        pageSize: 15,
        ind: 1,
        type: 1
      },
      goodAtTypeArray: [],
      industryArray: [],
      count: 1,
			list: []
    }
  },
  created () {
    this.getWriterCase()
	},
  methods: {
    // 获取写手
    getWriterCase() {
      let that = this
      getWriterClassApi().then(
        res => {
          that.goodAtTypeArray = res.data.writer_case_type
          that.industryArray = res.data.writer_case_ind
        })

      getWriterCaseApi(that.params).then(
        res => {
          that.list = res.data.list
          that.count = res.data.count
        })
    },
    handleChangeChange(value)
    {
      this.params.pageIndex = value
      this.getWriterCase()
    },
    handleTypeChange(value)
    {
      this.params.type = value
      this.getWriterCase()
    },
    handleIndChange(value)
    {
      this.params.ind = value
      this.getWriterCase()
    },
    toPath(id) { 
      console.log(id)
      this.$router.push({path:'/caseDetail',query:{id:id}});
    }
  }
}
</script>
<style scoped>
.caseBase {
  background: #EFF7FF;
  padding-top: 86px;
  padding-bottom: 40px;
}
.caseBase .pad {
  padding: 20px 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(184, 193, 208, 0.24);
  margin-bottom: 12px;
}
.checkArea.pad {
  padding-bottom: 0;
  margin-bottom: 30px;
}
.caseBase .typeName {
  width: 120px;
  display: block;
  font-weight: bold;
  padding-top: 3px;
}
/deep/.el-radio__inner {
  display: none;
}
/deep/.el-radio {
  padding: 10px 15px;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 16px;
  margin-right: 8px;
}
/deep/.el-radio-group {
  width: 1180px;
}
/deep/.el-radio__label {
  padding-left: 0;
}

/deep/.el-radio:hover{
  background: #EFF7FF;
  color: #1A70FD;
}
/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #fff;
}
/deep/.el-radio.is-checked,/deep/.el-radio__input.is-checked:hover+.el-radio__label  {
  background: #1A70FD;
}
.news a {
  display: block;
  color: #333;
  font-size: 20px;
}
.news .el-image {
  width: 120px;
  height: 90px;
  border-radius: 8px;
  margin-right: 20px;
}
.news h3 {
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 32px;
  max-width: 920px;
}
.news .tag-group {
  height: 30px;
}
.news .tag-group img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.news .tag-group h6 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.news .tag-group span {
  padding: 7px 9px;
  border: 1px solid #1E85F6;
  background: #EAF4FF;
  border-radius: 30px;
  font-size: 12px;
  color: #1E85F6;
  margin-right: 10px;
}
.el-pagination {
  padding: 40px 0;
  text-align: center;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev, /deep/.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>