import { request } from '@/utils/request'


// 获取写手
export const getWriterLisApi = () => {
  return request({
    url: '/index/get_wri_list',
    method: 'get',
  })
}

// 获取写手
export function getWriterApi(data) {
  return request({
    url: '/index/get_write_list',
    method: 'post',
    data: {
      ...data
    }
  })
}

// 获取写手详情
export function getWriterInfoApi(data) {
  return request({
    url: '/index/get_write_info',
    method: 'post',
    data: {
      ...data
    }
  })
}

// 写手获取分类
export function getWriterClassApi(params) {
  return request({
    url: '/index/get_write_class',
    method: 'get',
    params
  })
}


// 获取案例
export function getWriterCaseApi(data) {
    return request({
      url: '/index/get_writer_case_list',
      method: 'post',
      data: {
        ...data
      }
    })
}

// 获取案例详情
export function getWriterCaseInfoApi(data) {
  return request({
    url: '/index/get_writer_case_info',
    method: 'post',
    data: {
      ...data
    }
  })
}

// 获取首页合作案例
export function getPinpaihezuo() {
    return request({
      url: '/index/get_writer_pinpaihezuo',
      method: 'get',
    })
}


// 获取案例
export function getNewsApi(params) {
  return request({
    url: '/index/get_news_list',
    method: 'get',
    params
  })
}

// 获取案例详情
export function getNewsInfoApi(params) {
  return request({
    url: '/index/get_news_info',
    method: 'get',
    params
  })
}

// 获取案例详情
export function getUpdataApi(data) {
  return request({
    url: '/index/upload',
    method: 'post',
    data: {
      ...data
    }
  })
}


