export default {
    // token白名单
    whiteListApi: [
        '/index/get_user',
        '/index/get_user_login',
        '/index/out_login',
        '/index/get_media_list',
        '/index/get_zmt_list',
        '/index/get_hwmt_list',
        '/index/get_notify_num',
        '/index/get_notify',
        '/index/get_user_info',
        '/index/set_user_info',
        '/index/set_password',
        '/index/get_bill',
        '/index/get_invoice_user',
        '/index/set_invoice_user',
        '/index/get_invoice_list',
        '/index/get_invoice_sq_list',
        '/index/get_invoice_sq',
        '/index/get_invoice_info',
        '/index/publish',
        '/index/upload',
        '/index/get_order_list',
        '/index/get_gaigao_list',
        '/index/get_gaigao',
        '/index/get_tuigao',
        '/index/del_gaojian',
        '/index/get_gaojian_info',
        '/index/create_order',
        '/index/get_pay_order',
        '/index/get_daochu_order_list'
    ],
}