<template>
  <!-- 媒体资源库 -->
  <div class="media">
    <div class="container">
      <div class="pad checkArea">
        <div class="flex">
          <span class="typeName font-20">频道类型</span>
          <el-radio-group v-model="search.channel" @change="handleChannelChange">
            <el-radio v-for="item in channelType" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="flex">
          <span class="typeName font-20">综合门户</span>
          <el-radio-group v-model="search.portal" @change="handlePortalChange">
            <el-radio v-for="item in portalType" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="flex">
          <span class="typeName font-20">覆盖地区</span>
          <el-radio-group v-model="search.area" @change="handleAreaChange">
            <el-radio v-for="item in areaType" :label="item.value" :key="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="pad checkOtherType">
        <div class="flex flex-content-between flex-align-center">
          <div class="flex selectType">
            <span class="typeName font-20">更多选项</span>
            <div>
              <el-select v-model="search.newsResource" placeholder="新闻源" @change="handleNewsResourceChange">
                <el-option
                  v-for="item in newsResourceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-select v-model="search.entrance" placeholder="入口级别" @change="handleEntranceChange">
                <el-option
                  v-for="item in entranceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <!-- <el-select v-model="search.weekend" placeholder="可发媒体" @change="handleWeekendChange">
                <el-option
                  v-for="item in weekendType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <el-select v-model="search.url" placeholder="链接类型" @change="z``">
                <el-option
                  v-for="item in urlType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="inputType">
            <el-input placeholder="请输入媒体名称" v-model.trim="search.media" class="input-with-select">
              <template slot="append"><el-button type="primary" icon="el-icon-search" size="default" @click="SearchClick">搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="pad listType">
        <div class="flex flex-content-between">
          <el-radio-group v-model="listType" style="margin-bottom: 30px;">
            <el-button type="primary" round @click="reset">重置</el-button>
          </el-radio-group>
          <!-- <el-radio-group v-model="listType" style="margin-bottom: 30px;">
            <el-radio-button label="all" >全部</el-radio-button>
            <el-radio-button label="myCollection">我的收藏</el-radio-button>
          </el-radio-group> -->
          <div>
            <el-select v-model="search.sort" placeholder="默认排序" @change="handleSortChange">
                <el-option
                  v-for="item in sort"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="search.price" placeholder="价格" @change="handlePriceChange">
                <el-option
                  v-for="item in priceSort"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-select v-model="search.pc" placeholder="电脑权重" @change="handlePcChange">
                <el-option
                  v-for="item in pcSort"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="search.mobile" placeholder="移动权重" @change="handleMobileChange">
                <el-option
                  v-for="item in mobileSort"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <!-- <el-button type="primary" size="default" class="export"><i></i> 导出Excel</el-button> -->
              <!-- <el-button type="primary" size="default" class="export"> 全部</el-button> -->
          </div>
        </div>
      </div>
      <div class="pad list" v-if="listType === 'all'">
        <el-table
          :data="list"
          v-loading="loading"
          style="width: 100%">
          <el-table-column
            prop="name"
            label="媒体名称"
            width="200">
            <template slot-scope="scope">
              <i class="recommend" v-if="scope.row.type === 'recommend'"></i>
              <el-tooltip class="item" effect="light" :visible-arrow="false" placement="bottom-start" popper-class="effectName">
                <div slot="content">{{scope.row.name}}</div>
                <p class="title"><el-link :href="scope.row.case_url" target="_blank"  :underline="false">{{ scope.row.name }}</el-link></p>
				      </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="channel_name"
            label="媒体类型"
            width="140">
          </el-table-column>
          <el-table-column
            prop="money"
            label="价格"
            width="140">
            <template slot-scope="">
              <!-- <p v-if="scope.row.money !== ''" style="color: #DA373A;">{{ scope.row.money}}</p> -->
              <p><router-link to="/login?type=login">登录查看</router-link></p>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="pc_weight"
            label="电脑权重">
            <template slot-scope="scope">
              <p class="weight">{{scope.row.pc_weight}}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="mobile_weight"
            label="移动权重">
            <template slot-scope="scope">
              <p class="weight">{{scope.row.mobile_weight}}</p>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="news_source"
            label="新闻源"
            width="140">
          </el-table-column>
          <el-table-column
            prop="url_type"
            label="链接类型"
            width="140">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.url_type"  :key="index">{{ item.name }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="des"
            label="备注">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" :visible-arrow="false" placement="bottom-start" popper-class="effectName">
                <div slot="content">{{scope.row.des}}</div>
                <p>{{ scope.row.des }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="入口位置"
            width="110"> -->
            <!-- <template slot-scope="scope"> -->
              <!-- <div class="btn"  v-if="scope.row.portal != 1"> -->
                <!-- <el-link :href="scope.row.portal" target="_blank" :underline="false" ><i class="caseLink icon"></i> 入口</el-link> -->
                <!-- <div v-else><i class="caseLink icon"></i> 入口</div> -->
              <!-- </div> -->
              <!-- <div class="btn" @click="collect(scope.row)">
                <i class="collect icon" v-if="scope.row.isCollect === 0"></i>
                <i class="collected icon" v-else></i>
                <span :class="{'main-color': scope.row.isCollect === 1}"> 收藏</span>
              </div> -->
            <!-- </template> -->
          <!-- </el-table-column> -->
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleChangeChange"
          :page-size="20"
          :total="length">
        </el-pagination>
      </div>
			<div class="pad list" v-if="listType === 'myCollection'">
				<el-table
				  :data="scList"
				  style="width: 100%">
				  <el-table-column
				    prop="name1"
				    label="媒体名称2"
				    width="150">
				    <template slot-scope="scope">
				      <i class="recommend" v-if="scope.row.type === 'recommend'"></i>
				      <p><router-link to="/home">{{ scope.row.name1 }}</router-link></p>
				    </template>
				  </el-table-column>
				  <el-table-column
				    prop="name2"
				    label="媒体类型2">
				  </el-table-column>
				  <el-table-column
				    prop="name3"
				    label="价格">
				    <template slot-scope="scope">
				      <p v-if="scope.row.type === 'recommend'" style="color: #DA373A;">{{ scope.row.name3}}</p>
				      <p v-else>登录查看</p>
				    </template>
				  </el-table-column>
				  <el-table-column
				    prop="name4"
				    label="电脑权重">
				    <template slot-scope="scope">
				      <p class="weight">{{scope.row.name4}}</p>
				    </template>
				  </el-table-column>
				  <el-table-column
				    prop="name5"
				    label="移动权重">
				    <template slot-scope="scope">
				      <p class="weight">{{scope.row.name5}}</p>
				    </template>
				  </el-table-column>
				  <el-table-column
				    prop="name6"
				    label="新闻源">
				  </el-table-column>
				  <el-table-column
				    prop="name7"
				    label="链接情况">
				  </el-table-column>
				  <el-table-column
				    prop="name8"
				    label="备注">
				    <template slot-scope="scope">
				      <el-tooltip class="item" effect="light" :visible-arrow="false" placement="bottom-start" popper-class="effectName">
				        <div slot="content">{{scope.row.name8}}</div>
				        <p>{{ scope.row.name8 }}</p>
				      </el-tooltip>
				    </template>
				  </el-table-column>
				  <el-table-column
				    label="操作"
				    width="200">
				    <template slot-scope="scope">
				      <div class="btn"><router-link :to="scope.row.caseLink"><i class="caseLink icon"></i> 案例</router-link></div>
				      <div class="btn">
				        <i class="collect icon" v-if="scope.row.isCollect === '0'"></i>
				        <i class="collected icon" v-else></i>
				        <span :class="{'main-color': scope.row.isCollect === '1'}"> 收藏</span>
				      </div>
				    </template>
				  </el-table-column>
				</el-table>
				<el-pagination
				  background
				  layout="prev, pager, next"
          :current-change="handleChangeChange"
				  :page-size="pageSize"
				  :total="scLength">
				</el-pagination>
			</div>
    </div>
  </div>
</template>
<script>
import { getMediaType, getMediaList } from '@/api/media'
export default {
  name: 'MediaRepository',
  data () {
    return {
      search: {
        media: '',
        shoucang:'',
        channel: 1,
        portal: 1,
        area: 1,
        newsReso: 1,
        entrance: '',
        weekend: '',
        url: '',
        sort: '',
        price: '',
        pc: '',
        mobile:'',
        pageIndex: 1,
        pageSize: 20,
        loading: false
      },
      mediaName: '',
      listType: 'all',
      channelType: [], // 频道类型
      portalType: [], // 综合门户
      areaType: [], // 覆盖地区
      
      newsResourceType: [], // 新闻源
      entranceType: [], // 入口级别
      weekendType: [], // 可发媒体
      urlType: [], // 链接类型

      sort: [{ "value": 1, "label": "正序" }, { "value": 2, "label": "倒序" }], // 默认排序
      priceSort: [{ "value": 1, "label": "从高到低" }, { "value": 2, "label": "从低到高" }], // 价格排序
      pcSort: [{ "value": 1, "label": "从高到低" }, { "value": 2, "label": "从低到高" }], // pc权重排序
      mobileSort: [{"value":1, "label":"从高到低"}, {"value":2, "label":"从低到高"}], // 移动端排序

      length:1,
      list: [],
      scLength:1,
      scList:[]
    }
  },
  created () {
    this.getTypt()
    this.getList()
	},
  methods: {
    reset()
    {
      this.search = {
        media: '',
        shoucang:'',
        channel: 1,
        portal: 1,
        area: 1,
        newsReso: '',
        entrance: '',
        weekend: '',
        url: '',
        sort: '',
        price: '',
        pc: '',
        mobile:'',
        pageIndex: 1,
        pageSize: 10,
        loading: false
      }
    },
    handleChannelChange(value){
      this.search.channel = value
      this.getList()
    },
    handlePortalChange(value){
      this.search.portal = value
      this.getList()
    },
    handleAreaChange(value) {
      this.search.area = value
      this.getList()
    },
    handleNewsResourceChange(value) {
      this.search.newsReso = value
      this.getList()
    },
    handleEntranceChange(value) {
      this.search.entrance = value
      this.getList()
    },
    handleWeekendChange(value) {
      this.search.weekend = value
      this.getList()
    },
    handleUrlChange(value) {
      this.search.url = value
      this.getList()
    },
    handleSortChange(value) {
      this.search.sort = value
      this.getList()
    },
    handlePriceChange(value) {
      this.search.price = value
      this.getList()
    },
    handlePcChange(value) {
      this.search.pc = value
      this.getList()
    },
    handleMobileChange(value) {
      this.search.mobile = value
      this.getList()
    },
    SearchClick(){
      this.getList()
    },
    collect(value)
    {
      if (value.isCollect == 1) {
        value.isCollect = 0
      } else { 
        value.isCollect = 1
      }
    },
    // 获取分类
    getTypt() {
      let that = this
      getMediaType().then(
        res => { 
          that.channelType = res.data.channel
          that.portalType = res.data.portal
          that.areaType = res.data.area
          that.newsResourceType = res.data.news_resource
          that.entranceType = res.data.entrance
          that.weekendType = res.data.weekend
          that.urlType = res.data.url_type
        })
    },
    handleChangeChange(value)
    {
      this.search.pageIndex = value
      this.getList()
    },
    getList()
    {
      let that = this
      that.loading = true
      getMediaList(this.search).then(res => { 
        that.list = res.data.list
        that.length = res.data.count
        that.loading = false
      })
    }
	}
}
</script>
<style scoped>
.media {
  background: #EFF7FF;
  padding-top: 86px;
  padding-bottom: 40px;
}
.media .pad {
  padding: 20px 25px;
  padding-bottom: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(184, 193, 208, 0.24);
  margin-bottom: 12px;
}
.media .pad .flex {
  margin-top: 10px;
}
.media .typeName {
  width: 120px;
  display: block;
  font-weight: bold;
  padding-top: 3px;
}
.title a {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
/deep/.el-radio__inner {
  display: none;
}
/deep/.el-radio {
  padding: 10px 15px;
  text-align: center;
  border-radius: 30px;
  margin-bottom: 16px;
  margin-right: 8px;
}
/deep/.el-radio__label {
  padding-left: 0;
}

/deep/.el-radio:hover{
  background: #EFF7FF;
  color: #1A70FD;
}
/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #fff;
}
/deep/.el-radio.is-checked,/deep/.el-radio__input.is-checked:hover+.el-radio__label  {
  background: #1A70FD;
}
/deep/.el-radio-group {
  width: 1180px;
}
.media .pad.checkOtherType {
  padding-bottom: 20px;
}
.media .pad.checkOtherType .flex {
  margin-top: 0;
}
.el-select {
  width: 105px;
  margin-right: 10px;
}
/deep/.el-select .el-input__inner {
  border-radius: 30px;
  height: 36px;
  border: 1px solid #EBEBEB;
  line-height: 36px;
}
.media .pad.checkOtherType .typeName {
  width: 95px;
}
/deep/.el-icon-arrow-up:before {
  content: '';
  width: 8px;
  height: 6px;
  background: url(../../../static/images/home/square.png) no-repeat;
  display: inline-block;
}
/deep/.el-select .el-input__icon {
  line-height: 35px;
}
/deep/.el-select .el-input__inner::placeholder {
    color: #333;
}
/deep/.el-select .el-input__innert::-webkit-input-placeholderr{ 
  color:#333!important; 
}
/deep/.el-select .el-input__inner:-moz-placeholder{ 
  color:#333; 
}
/deep/.el-select .el-input__inner::-moz-placeholder{ 
  color:#333; 
} 
/deep/.el-select .el-input__inner:-ms-input-placeholder{ 
  color:#333; 
}
.media .inputType {
  padding-left: 20px;
  border-radius: 30px;
  border: 1px solid #EBEBEB;
  background: #EFF7FF;
}
.media /deep/.el-input-group>.el-input__inner {
  border: none;
  background: none;
  padding-left: 0;
  height: 36px;
}
.media /deep/.el-input-group__append {
  padding: 0;
  border: none;
  border-radius: 30px;
  background: #1A70FD;
  color: #fff;
}
.media /deep/.el-input-group__append .el-button {
  border: none;
  margin: 0;
  padding: 0;
  width: 95px;
  height: 36px;
}
.media .pad.listType {
  padding: 1px 25px;
}
.listType /deep/.el-radio-button__inner {
  padding: 0;
  width: 118px;
  height: 40px;
  line-height: 40px;
  background: #EFF7FF;
  color: #333;
}
.listType /deep/.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 10px 0 0 10px;
}
.listType /deep/.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 10px 10px 0;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background: #1A70FD;
  color: #fff;
}
.listType .export {
  border-radius: 30px;
  background: none;
  color: #1A70FD;
  vertical-align: middle;
  width: 118px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}
.listType .export i{
  width: 14px;
  height: 15px;
  background: url(../../../static/images/home/export.svg) no-repeat;
  display: inline-block;
  margin-bottom: -2px;
}
.listType .el-radio-group {
  margin-bottom: 10px!important;
  width: 300px;
}
.list .recommend {
  width: 16px;
  height: 16px;
  background: url(../../../static/images/home/recommend.png) no-repeat;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
.list p {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  width: 360px;
  vertical-align: middle;
}
.list p a {
  color: #1677FF;
}
.list .weight {
  width: 48px;
  height: 21px;
  background: url(../../../static/images/home/baidu.png) no-repeat;
  background-size: contain;
  padding-left: 25px;
  color: #fff;
}

.list .btn {
  padding: 5px 12px;
  border: 1px solid #1677FF;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}
.list .btn a {
  color: #1677FF;
}
.list .btn .icon {
  width: 18px;
  display: inline-block;
  margin-bottom: -4px;
  height: 18px;
}
.list .btn .caseLink {
  background: url(../../../static/images/home/case.svg) no-repeat;
}
.list .btn .collect {
  background: url(../../../static/images/home/uncollect.svg) no-repeat;
}
.list .btn .collected {
  background: url(../../../static/images/home/collect.svg) no-repeat;
}
.el-pagination {
  padding: 40px 0;
  text-align: center;
  background: #fff;
}
</style>